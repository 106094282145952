<template>
	<div>
		<div class="data-list list-days-off" v-if="listDaysOff.length">
			<div class="row-space-tbf header-count-list">
				<div class="space-left"></div>
				<div class="content">
					<div class="text-list-length">
						{{ $t('general.list_length') }} <span class="number">{{ totalResults }}</span>
					</div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="row-space-tbf header-list">
				<div class="space-left"></div>
				<div class="content">
					<div class="column-filter column-name">
						<div class="text">{{ $t('general.name') }}</div>
					</div>
					<div v-if="$resize && $mq.above(1400)" class="column-filter column-type">
						<div class="text">{{ $t('days_off.type') }}</div>
					</div>
					<div v-if="$resize && $mq.above(700)" class="column-filter column-for_all">
						<div class="text">{{ $t('days_off.users') }}</div>
					</div>
					<div class="column-filter column-count-days">
						<div class="text">{{ $t('days_off.free_days_count') }}</div>
					</div>
					<div v-if="$resize && $mq.above(1040)" class="column-filter column-period">
						<div class="text">{{ $t('days_off.interval') }}</div>
					</div>
					<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
				</div>
				<div class="space-right"></div>
			</div>

			<template v-for="day_off in listDaysOff">
				<div class="row-space-tbf row-list-item">
					<div class="space-left"><icon-calendar v-if="$resize && $mq.above(781)"/></div>
					<div class="content">
						<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
						<div class="column-name-title column-name">
								<div class="name">{{ day_off.name }}</div>
						</div>
						<div v-if="$resize && $mq.above(1400)" class="column-simple-text column-type">
							<div class="text">{{ $t(`days_off.options_type.${day_off.type}`) }}</div>
						</div>
						<div v-if="$resize && $mq.above(700)" class="column-simple-text column-for_all">
							<div class="text" v-if="day_off.for_all">{{ $t('days_off.for_all_simple') }}</div>
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-else>
								<div class="users-avatars">
									<div class="image" v-for="user in day_off.users.slice(0, 3)">
										<img :src="user.avatar">
									</div>
									<div class="image plus-icon" v-if="day_off.users.length > 3">
										<icon-plus />
										<span class="numer">{{ day_off.users.length - 3 }}</span>
									</div>
								</div>
								<template slot="popover">
									<div class="simple-text">{{ day_off.users.map(el => { return el.name }).join(", ") }}</div>
								</template>
							</v-popover>
						</div>
						<div class="column-simple-text column-count-days">
							<div class="text">{{ $tc('days_off.days', day_off.free_days) }}</div>
						</div>
						<div v-if="$resize && $mq.above(1040)" class="column-simple-text column-period">
							<div class="text" v-if="day_off.start_date == day_off.end_date">
								{{ day_off.start_date | moment('DD.MM.YYYY') }}
							</div>
							<div class="text" v-else>
								{{ day_off.start_date | moment('DD.MM.YYYY') }} - {{ day_off.end_date | moment('DD.MM.YYYY') }}
							</div>
						</div>
						<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
							<div class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef'+day_off.id" :id="'dropdownEdit'+day_off.id" data-toggle="dropdown">
									<icon-edit-mobile />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ day_off.id " :aria-labelledby="'dropdownEdit'+day_off.id">
									<div class="dropdown-item" @click="showModal('crud_day_off', {crud: 'edit', id: day_off.id})"><div class="simple-text">{{$t('general.edit')}}</div></div>
									<div class="dropdown-item" @click="showModal('delete', {type: 'vacation_day', from: 'index_vacation_days_calendar', model: day_off})"><div class="simple-text">{{$t('general.delete')}}</div></div>
								</div>
							</div>
						</div>
					</div>
					<div class="space-right">
						<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
							<div class="button-edit" :ref="'dropdownEditRef'+day_off.id" :id="'dropdownEdit'+day_off.id" data-toggle="dropdown">
								<icon-edit />
							</div>
							<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ day_off.id " :aria-labelledby="'dropdownEdit'+day_off.id">
								<div class="dropdown-item" @click="showModal('crud_day_off', {crud: 'edit', id: day_off.id})"><div class="simple-text">{{$t('general.edit')}}</div></div>
								<div class="dropdown-item" @click="showModal('delete', {type: 'vacation_day', from: 'index_vacation_days_calendar', model: day_off})"><div class="simple-text">{{$t('general.delete')}}</div></div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
		<div class="row-space-tbf list-empty" v-else>
			<div class="space-left"></div>
			<div class="content full">
				<div class="title">{{ $t('empty.report-title')}}</div>
			</div>
			<div class="space-right"></div>
		</div>
	</div>
</template>

<script>
    import IconPlus from '../../Icons/Plus'
    import IconArrow from '../../Icons/Arrow'
    import IconEdit from '../../Icons/EditDots'
	import IconEditMobile from '../../Icons/Edit'
    import IconCalendar from '../../Icons/Calendar'

	export default {
		props: {
			listDaysOff: Array,
			totalResults: Number
		},
		data() {
			return {
				sortBy: '',
            	sortDirection: 'asc'
			}
		},
		components: {
			IconArrow,
			IconPlus,
			IconEdit,
			IconEditMobile,
			IconCalendar
		},
		methods: {
			sortList(column) {
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;
				this.$emit('sorted_data')
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.row-space-tbf.search-row{
		&.reports {
			padding-top: 0;
		}
	} 
	.list-days-off{
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-name{
			flex: 1 1 auto;
			display: flex;
			align-items: center;
			.name{
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}

		.column-type{
			flex: 0 0 250px;
		}
		.column-for_all{
			flex: 0 0 200px;
		}
		.column-period{
			flex: 0 0 200px;
			text-align: right;
			justify-content: flex-end;
			.text{
				text-transform: capitalize;
			}
		}
		.column-count-days{
			flex: 0 0 100px;
		}

		@media (max-width: 1500px) {
			.column-type{
				flex: 0 0 200px;
			}
			.column-for_all{
				flex: 0 0 150px;
			}
			.column-period{
				flex: 0 0 120px;
			}
		}
		@media (max-width: 1300px) {
			.column-type{
				flex: 0 0 200px;
			}
			.column-for_all{
				flex: 0 0 100px;
			}
			.column-period{
				flex: 0 0 120px;
			}
		}
	}
	.list-empty .content {
		padding: 10px;
		padding-left: 0px;
		border-top: none;
		color: $grey
	}
</style>